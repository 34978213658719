<template>
  <StitchDialog
    class="detail-style"
    fullscreen
    visible
    @close="emitClose"
  >
    <template v-if="item">
      <DetailHeader
        slot="title"
        :item="item"
        @edit-item="openEditStyleForm"
        @delete-item="deleteStyle"
      />
      <div class="detail-style__body">
        <DetailVersionList
          :active-version="activeVersion"
          :can-edit="canUpdateStyle"
          :versions="versions"
          @add-new-version="openVersionCreateForm"
          @select-version="handleSelectVersion"
          @rename-version="openVersionRenameForm"
          @delete-version="deleteVersionAndShowConfirmation"
          @rerender-version="rerenderVersionAndShowConfirmation"
          @set-main-version="setStyleMainVersion"
          @trigger-download="triggerDownloadFile"
          @poll-versions-render-status="handleVersionPolling"
        />
        <OptionList
          v-if="activeVersion"
          :active-version="activeVersion"
          :options="options"
          @add-option="handleCreateOption"
          @update-option="handleUpdateOption"
          @delete-option="handleDeleteOption"
          @add-option-images="handleAddOptionImages"
          @update-option-image="handleUpdateOptionImage"
          @delete-option-image="handleDeleteOptionImage"
        />
      </div>

      <!-- Update item form -->
      <FormGenerator
        ref="editStyleForm"
        :form-configuration="styleFormConfiguration"
        :options="formFilters"
        :original-data="formOriginalData"
        :show-form-generator="showEditStyleForm"
        :specific-step-id-to-edit="specificStepIdToEdit"
        @form-close="closeEditStyleForm"
        @form-complete="completeEditStyleForm"
        @execute-trigger="executeTrigger"
      />

      <!-- Create style version form -->
      <FormGenerator
        ref="createVersionForm"
        :additional-data="additionalVersionFormData"
        :default-data="defaultVersionFormData"
        :form-configuration="versionCreateFormConfiguration"
        :options="formFilters"
        :show-form-generator="showVersionCreateForm"
        @form-close="closeVersionCreateForm"
        @form-complete="completeVersionCreateForm"
        @execute-trigger="executeTrigger"
      />

      <!-- Rename style version form -->
      <FormGenerator
        ref="renameVersionForm"
        :additional-data="additionalVersionFormData"
        :form-configuration="versionRenameFormConfiguration"
        :original-data="defaultVersionFormData"
        :show-form-generator="showVersionRenameForm"
        @form-close="closeVersionRenameForm"
        @form-complete="completeVersionRenameForm"
      />

      <!-- Create/Update Option form -->
      <FormGenerator
        ref="optionForm"
        :additional-data="additionalOptionFormData"
        :form-configuration="optionFormConfiguration"
        :options="{ formPlmOptions, ...formFilters }"
        :original-data="defaultOptionFormData"
        :show-form-generator="showOptionForm"
        :specific-step-id-to-edit="specificStepIdToEdit"
        @form-close="closeOptionForm"
        @form-complete="completeOptionForm"
      />
    </template>
    <template v-else-if="isLoadingItem">
      <StitchLoader />
    </template>
    <LibraryDetailError
      v-if="errorStatus"
      :active-library="libraryType"
      :error-status="errorStatus"
    />
  </StitchDialog>
</template>

<script>
import { FORM_NAME } from '@/constants/formName'
import { mapActions, mapGetters } from 'vuex'
import { convertObjectKeysToCamelCase } from '@/services/utils'
import { getFormConfig } from '@/services/formUtils'
import { LIBRARY_TYPE } from '@/constants/libraryType'
import { JOB_STATUS } from '@/constants/loadingStatus'
import { TRACKER_OBJECTS, TRACKER_EVENTS } from '@/constants/tracker'
import DetailHeader from '../DetailHeader'
import DetailVersionList from './components/DetailVersionList'
import LibraryDetailError from '../LibraryDetailError'
import OptionList from '@/components/option/OptionList'

// triggerDownload
import { DataUtils } from '@/mixins/utils.js'
import VueTypes from 'vue-types'
import { canPermitAction } from '@/services/permissions'
import { ROLE_FLAG } from '@/constants/roleFlag'
import { FILTER_TYPE } from '@/constants/filterType'
import FeatureFlags from '@/services/featureFlags'
import placeholder360 from '@/assets/images/placeholder360.png'
import { IMAGE_VIEW_ANGLE } from '@/constants/image'
import { USER_ROLE } from '@/constants/roleType'

export default {
  name: 'DetailStyle',

  components: {
    OptionList,
    DetailHeader,
    DetailVersionList,
    LibraryDetailError
  },

  mixins: [DataUtils],

  props: {
    itemId: VueTypes.number.isRequired
  },

  emits: ['close'],

  data () {
    return {
      isLoadingItem: false,
      activeVersion: null,
      showEditStyleForm: false,
      specificStepIdToEdit: null,
      defaultDataForUpdate: null,
      defaultVersionFormData: null,
      defaultOptionFormData: null,
      showVersionRenameForm: false,
      showVersionCreateForm: false,
      showOptionForm: false,
      formStepColorway: {
        MANUAL: 'colorwayManual',
        EDIT: 'colorwayEdit',
        PLM: 'colorwayPlm'
      },
      formStepStyle: {
        DETAILS: 'details'
      },
      errorStatus: null
    }
  },

  computed: {
    ...mapGetters([
      'getItemDetail',
      'getMainVersionFromActiveStyle',
      'getVersionById',
      'getOptionsByVersionId',
      'getAvailableFilters',
      'getOptionById',
      'getPlmOptions',
      'getVersionsUploadInProgress',
      'getTags'
    ]),

    ...mapGetters({
      user: 'getCognitoUserData'
    }),

    /**
     * @returns {object}
     */
    item () {
      return this.getItemDetail()
    },

    /**
     * @returns {boolean}
     */
    canAccessRenderEngineChoice () {
      return FeatureFlags.canTeamAccessRenderEngineChoice()
    },

    /**
     * @returns {boolean}
     */
    isVendorUser () {
      return this.user.role === USER_ROLE.VENDOR.name
    },

    /**
     * @returns {object} OriginalData
     */
    formOriginalData () {
      // this is meant to normalize attributes for the FormGenerator
      const { attributes, ...formOriginalData } = {
        ...this.item,
        ...this.item.attributes
      }

      // for vendors, company is always added by default
      // the role is used to distinguish between users
      // inside the FormGenerator
      if (this.isVendorUser) {
        formOriginalData.vendorCompanyId =
          this.item.vendorCompanyId ?? this.user.vendor_company.id
        formOriginalData.role = USER_ROLE.VENDOR.name
      } else if (this.item.vendorCompanyId) {
        formOriginalData.role = this.user.role
      }

      return formOriginalData
    },

    /**
     * @returns {Array}
     */
    pendingVersions () {
      return this.getVersionsUploadInProgress[this.itemId]
    },

    /**
     * @returns {Array}
     */
    versions () {
      if (!this.getItemDetail()) {
        // early return if getItemDetail is empty (when fetchItemDetail within created() hasn't been resolved yet)
        return
      }

      const versions = [...this.getItemDetail().versions]

      if (this.pendingVersions) {
        this.pendingVersions.forEach(pendingVersion => {
          if (pendingVersion.isMain) {
            return versions.unshift(pendingVersion)
          }

          return versions.push(pendingVersion)
        })
      }

      return versions
    },

    /**
     * @returns {object}
     */
    mainVersion () {
      return this.getMainVersionFromActiveStyle
    },

    /**
     * @returns {Array}
     */
    options () {
      const { id } = this.activeVersion
      const options = this.getOptionsByVersionId(id).map(option => {
        // create a new array for images to avoid mutating Vuex state
        let newImages = [...option.images]

        // if there are any slideshow images,
        // add a placeholder for the slideshow
        if (option.slideshowImages.length > 0) {
          const hasView360Placeholder = newImages.some(
            img => img.view_name === IMAGE_VIEW_ANGLE.VIEW360
          )

          if (!hasView360Placeholder) {
            const view360Placeholder = {
              id: Math.ceil(Math.random() * 1000000),
              is_thumbnail: false,
              view_name: IMAGE_VIEW_ANGLE.VIEW360,
              file_url: placeholder360
            }
            // Create a new array with the placeholder at the beginning
            newImages = [view360Placeholder, ...newImages]
          }
        }

        // Return a new object with the updated images array
        return {
          ...option,
          images: newImages
        }
      })

      return options
    },

    /**
     * @returns {Array}
     */
    formFilters () {
      if (this.canAccessRenderEngineChoice) {
        return convertObjectKeysToCamelCase({
          ...FILTER_TYPE.RENDER_ENGINE_FILTERS,
          ...this.getAvailableFilters(),
          tags: this.getTags()
        })
      }

      return convertObjectKeysToCamelCase({
        ...this.getAvailableFilters(),
        tags: this.getTags()
      })
    },

    /**
     * @returns {Array}
     */
    formPlmOptions () {
      const plmOptions = this.getPlmOptions()

      if (!this.item.plmCode || !plmOptions) {
        return []
      }

      return plmOptions.map(option => {
        return {
          name: option,
          id: option
        }
      })
    },

    /**
     * @returns {object}
     */
    styleFormConfiguration () {
      const config = getFormConfig(this.libraryType)

      if (this.isVendorUser || this.item.vendorCompanyId) {
        config.steps[1].fields[5].visible = false
      }

      return config
    },

    /**
     * @returns {object}
     */
    additionalVersionFormData () {
      return {
        groupId: this.item.groupId,
        styleId: this.itemId,
        versionId:
          (this.defaultVersionFormData && this.defaultVersionFormData.id) ||
          undefined
      }
    },

    /**
     * @returns {object}
     */
    additionalOptionFormData () {
      return {
        styleId: this.itemId,
        versionId: (this.activeVersion && this.activeVersion.id) || undefined,
        optionId:
          (this.defaultOptionFormData && this.defaultOptionFormData.optionId) ||
          undefined
      }
    },

    /**
     * @returns {object}
     */
    versionCreateFormConfiguration () {
      const config = getFormConfig(FORM_NAME.VERSION_CREATE)
      config.steps[0].fields[1].visible = this.canAccessRenderEngineChoice

      return config
    },

    /**
     * @returns {object}
     */
    versionRenameFormConfiguration () {
      return getFormConfig(FORM_NAME.VERSION_RENAME)
    },

    /**
     * @returns {object}
     */
    optionFormConfiguration () {
      return getFormConfig(FORM_NAME.OPTION_CREATE)
    },

    /**
     * @returns {string}
     */
    libraryType () {
      return LIBRARY_TYPE.STYLE
    },

    /**
     * @returns {boolean}
     */
    canUpdateStyle () {
      return canPermitAction(ROLE_FLAG.UPDATE_STYLE)
    }
  },

  watch: {
    /**
     * @param {Array} value
     */
    versions (value) {
      // force a rerender of the activeVersion once the fake version gets replaced by the real one
      if (this.activeVersion && this.activeVersion.fakeId) {
        const replacedVersion = value.find(
          version => version.name === this.activeVersion.name
        )

        this.activeVersion = replacedVersion || this.activeVersion
      }
    }
  },

  async created () {
    if (this.itemId !== null) {
      this.isLoadingItem = true

      try {
        await this.fetchItemDetail({
          itemId: this.itemId,
          libraryType: this.libraryType
        })
      } catch (error) {
        if (error.response) {
          this.errorStatus = error.response.status
        } else {
          throw error
        }
      }

      if (this.mainVersion) {
        this.activeVersion = this.mainVersion
      }

      this.isLoadingItem = false
    }
  },

  beforeDestroy () {
    this.clearItemDetail({ libraryType: this.libraryType })
  },

  methods: {
    ...mapActions([
      'fetchItemDetail',
      'fetchTags',
      'clearItemDetail',
      'deleteItem',
      'deleteStyleVersion',
      'rerenderStyleVersion',
      'fetchPlmOptions',
      'fetchJobDetailWithTasks',
      'fetchVersionRenderStatus',
      'setMainVersion',
      'deleteOption',
      'updateStyleOptionImage',
      'deleteStyleOptionImage',
      'addStyleOptionImages'
    ]),

    /**
     * @param {string} versionsId
     */
    async handleVersionPolling (versionsId) {
      const statusDetails = await this.fetchVersionRenderStatus(versionsId)
      const startedJob = statusDetails.find(
        detail => detail.status === JOB_STATUS.STARTED
      )

      if (startedJob) {
        const renderingVersion = this.versions.find(
          version => version.renderJobId === startedJob.id
        )

        this.fetchJobDetailWithTasks({
          jobId: startedJob.id,
          versionId: renderingVersion.id
        })
      }
    },

    /**
     */
    emitClose () {
      this.$emit('close')
    },

    /**
     * @param {object} payload
     */
    handleSelectVersion (payload) {
      const { version } = payload

      this.activeVersion = version

      this.$tracking.trackEvent({
        object: TRACKER_OBJECTS.STYLE_VERSION,
        action: TRACKER_EVENTS.VIEWED,
        category: this.libraryType,
        item: version,
        value: version.id
      })
    },

    /**
     * @param {object} payload
     */
    setStyleMainVersion (payload) {
      this.setMainVersion(payload)
    },

    /**
     */
    handleCreateOption () {
      const plmCode = this.item.plmCode
      this.defaultOptionFormData = null

      if (plmCode) {
        this.defaultOptionFormData = {
          plmCode
        }

        this.fetchPlmOptions({
          styleId: this.itemId,
          versionId: this.activeVersion.id,
          onlyUnassigned: true
        })
      } else {
        this.specificStepIdToEdit = this.formStepColorway.MANUAL
      }

      this.openOptionForm()
    },

    /**
     */
    openOptionForm () {
      this.fetchTags({
        groupId: this.item.groupId,
        seasonId: this.item.seasonId,
        isOptionForm: true
      })

      this.showOptionForm = true
    },

    /**
     * @param {object} payload
     */
    handleUpdateOption (payload) {
      const plmCode = this.item.plmCode
      const versionId = this.activeVersion.id
      const optionId = payload.optionId
      const option = this.getOptionById({
        versionId,
        optionId
      })

      this.specificStepIdToEdit = this.formStepColorway.EDIT

      this.defaultOptionFormData = {
        ...convertObjectKeysToCamelCase(option),
        plmCode,
        versionId,
        optionId
      }

      this.openOptionForm()
    },

    /**
     */
    closeOptionForm () {
      this.showOptionForm = false
      this.defaultOptionFormData = null
      this.specificStepIdToEdit = null
    },

    /**
     * @param {number} success
     */
    completeOptionForm (success) {
      if (success) {
        this.closeOptionForm()
      }
    },

    /**
     * @param {object} payload
     * @param {string} payload.url
     * @param {string} payload.object
     */
    triggerDownloadFile ({ url, object }) {
      this.triggerDownload(url)

      this.$tracking.trackEvent({
        object,
        action: TRACKER_EVENTS.DOWNLOADED,
        category: this.libraryType,
        label: url,
        value: this.activeVersion.id,
        item: this.activeVersion
      })
    },

    /**
     */
    closeEditStyleForm () {
      this.defaultDataForUpdate = null
      this.showEditStyleForm = false
    },

    /**
     */
    openEditStyleForm () {
      if (this.item.plmCode) {
        this.specificStepIdToEdit = this.formStepStyle.DETAILS
      } else {
        this.specificStepIdToEdit = null
      }

      this.showEditStyleForm = true
    },

    /**
     * @param {object} payload
     */
    openVersionCreateForm (payload) {
      if (payload) {
        this.defaultVersionFormData = {
          ...this.defaultVersionFormData,
          ...payload
        }
      }

      this.showVersionCreateForm = true
    },

    /**
     */
    closeVersionCreateForm () {
      this.defaultVersionFormData = null
      this.showVersionCreateForm = false
    },

    /**
     * @param {string} triggerMethodName
     * @param {object} triggerData
     */
    async executeTrigger (triggerMethodName, triggerData) {
      await this[triggerMethodName](triggerData)
    },

    /**
     * @param {object} triggerData
     */
    async formFetchTags (triggerData) {
      const { groupId, seasonId } = triggerData.processedItemData.new

      if (groupId && seasonId) {
        await this.fetchTags({
          groupId: groupId,
          seasonId: seasonId,
          isOptionForm: true
        })
      }
    },

    /**
     * @param {boolean} success
     */
    completeVersionCreateForm (success) {
      if (success) {
        this.closeVersionCreateForm()
      }
    },

    /**
     * @param {boolean} success
     */
    completeEditStyleForm (success) {
      if (success) {
        this.closeEditStyleForm()
      }
    },

    /**
     * @param {object} payload
     */
    async deleteStyle (payload) {
      await this.deleteItem(payload)

      this.emitClose()

      this.$message({
        showClose: true,
        message: `${payload.name} has been deleted`
      })
    },

    /**
     * @param {object} payload
     */
    openVersionRenameForm (payload) {
      this.defaultVersionFormData = {
        ...payload
      }
      this.showVersionRenameForm = true
    },

    /**
     * @param {number} success
     */
    completeVersionRenameForm (success) {
      if (success) {
        this.closeVersionRenameForm()
      }
    },

    /**
     */
    closeVersionRenameForm () {
      this.defaultVersionFormData = null
      this.showVersionRenameForm = false
    },

    /**
     * @param {object} payload
     */
    async deleteVersionAndShowConfirmation (payload) {
      await this.deleteStyleVersion(payload)

      this.activeVersion = this.mainVersion

      this.$message({
        showClose: true,
        message: `${payload.versionName} has been deleted`
      })
    },

    /**
     */
    async rerenderVersionAndShowConfirmation () {
      const styleId = this.itemId
      const versionId = this.activeVersion.id
      const versionName = this.activeVersion.name

      await this.rerenderStyleVersion({
        styleId,
        versionId
      })

      this.activeVersion = this.mainVersion

      this.$message({
        showClose: true,
        message: `Style Version "${versionName}" has been triggered for a re-render, please wait ⏳`
      })
    },

    /**
     * @param {object} payload
     */
    async handleDeleteOption (payload) {
      const styleId = this.itemId
      const versionId = this.activeVersion.id
      const { optionId, optionName } = payload

      await this.deleteOption({
        styleId,
        versionId,
        optionId
      })

      this.$message({
        showClose: true,
        message: `${optionName} has been deleted`
      })
    },

    /**
     * @param {object} payload
     */
    handleAddOptionImages (payload) {
      this.addStyleOptionImages(payload)
    },

    /**
     * @param {object} payload
     */
    handleUpdateOptionImage (payload) {
      this.updateStyleOptionImage(payload)
    },

    /**
     * @param {object} payload
     */
    handleDeleteOptionImage (payload) {
      this.deleteStyleOptionImage(payload)
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-style {
  /deep/ .el-dialog {
    max-width: spacing(190);
    margin: auto;
    padding: 0;
  }

  /deep/ .el-dialog__body {
    overflow-y: hidden;
  }
}

.detail-style__body {
  display: flex;
  width: 100%;
  border-top: $border-divider;
}
</style>
